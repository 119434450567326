import React, { useEffect, useState } from "react";
import logo from "../Assets/logo.png";
import tele from "../Assets/socialmedia/telegram.png";
import gitimg from "../Assets/socialmedia/git.png";
import disc from "../Assets/socialmedia/discard.png";
import medium from "../Assets/socialmedia/medium.png";
import instagram from "../Assets/socialmedia/instagram.png";
import reddit from "../Assets/socialmedia/reddit.png";
import thread from "../Assets/socialmedia/thread.png";

import twitter from "../Assets/socialmedia/twitter.png";
import fb from "../Assets/socialmedia/fb.png";
import axios from "axios";
import configurl from "../../src/config/config";
import { Link } from "react-router-dom";
export default function Footer() {
  const [CmsFooter, setCmsFooter] = useState([]);
  const [CmsImage, setCmsImage] = useState([]);
  
  const [Sociallink, setCmsSociallink] = useState([]);

  const getAlldata = async () => {
    axios.get(configurl.backurl + "/api/GetCmsFooter").then((res) => {
      setCmsFooter(res.data);
    });
  };

  const getImagedata = async () => {
    axios.get(configurl.backurl + "/api/GetCmsimage").then((res) => {
      setCmsImage(res.data);
    });
  };

  
  const getsociallink = async () => {
    axios.get(configurl.backurl + "/api/GetCmsSocialLink").then((res) => {
      setCmsSociallink(res.data);
    });
  };
  

  useEffect(() => {
    getAlldata();
    getImagedata();
  }, []);

  useEffect(() => {
    getsociallink()
  }, []);
  return (
    <>
      <div className="footer pt-3 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mt-4 mt-lg-3">
              <Link to="/">
                
              <img
                src={`${configurl.backurl}/CmsImage/${CmsImage[0]?.image}`}
                className="img-fluid logo"
              />
              </Link>
            </div>
            <div className="col-lg-4 mt-4 mt-lg-3">
              <p className="subheading mb-0">
             {`${
                        CmsFooter[0]?.identifier == "Footer" ? (
                          CmsFooter[0]?.content
                        ) : (
                          "Loading..."
                        )
                      }`}
              
             

              </p>
            </div>
            <div className="col-lg-4 mt-4 mt-lg-3 socialmedia">
              {/* <a
                href="https://www.facebook.com/darkcoindmv?mibextid=LQQJ4d"
                target="_blank"
              >
                <img src={fb} className="img-fluid media" />
              </a>
              <a href="https://twitter.com/Darkcoindmv" target="_blank">
                <img src={twitter} className="img-fluid media" />
              </a> */}
              <a
                href={Sociallink[0]?.content}
                target="_blank"
              >
                <img src={disc} className="img-fluid media" />
              </a>
              <a href={Sociallink[1]?.content} target="_blank">
                <img src={tele} className="img-fluid media" />
              </a>
              <a href={Sociallink[2]?.content} target="_blank">
                <img src={gitimg} className="img-fluid media" />
              </a>
              <a href={Sociallink[3]?.content} target="_blank">
                <img src={thread} className="img-fluid media" />
              </a>
              <a href={Sociallink[4]?.content} target="_blank">
                <img src={reddit} className="img-fluid media" />
              </a>

              <a href={Sociallink[5]?.content}target="_blank">
                <img src={instagram} className="img-fluid media" />
              </a>

              <a href={Sociallink[6]?.content} target="_blank">
                <img src={medium} className="img-fluid media" />
              </a>

              <a href={Sociallink[7]?.content} target="_blank">
                <img src={fb} className="img-fluid media" />
              </a>

              <a href={Sociallink[8]?.content} target="_blank">
                <img src={twitter} className="img-fluid media" />
              </a>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
