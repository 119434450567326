import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import gecko_logo from "../../Assets/gecko_logo.png";


const Partners = () => {
  return (
    <>
      <section className="hm_contract pt-5">
        <div className="hm_contract_banner  py-5">
          <Container className="container_90">
            <Row className="justify-content-center">
              <Col xl={11} xxl={10}>
                <div>
                  <h1 className="hm_lnr_grd_txt text-uppercase text-center mb-0">
                    Partners
                  </h1>
                  {/* <h3 className="text-center h3_fw_300">gecko terminal</h3> */}
                  <Row className="mx-auto mt-4 mt-sm-5">
                    <Col
                      md={12}
                      lg={12}
                      className="ps-0 d-flex align-items-center justify-content-center"
                    >
                      <a href="#" target="_blank">
                       <img src={gecko_logo} alt="faq" className="img-fluid img_partner" />
                       
    </a>
                    </Col>
                 
                  </Row>
                </div>
              </Col>
            </Row>{" "}
          </Container>
        </div>
      
      </section>
    </>
  );
};

export default Partners;
