import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "lottie-react";

import darkPayImageOne from "../../Assets/homepage/darkpayCardImgOne.svg";
import darkPayImageTwo from "../../Assets/homepage/darkpayCardImgTwo.svg";
import darkPayImageThree from "../../Assets/homepage/darkpayCardImgThree.svg";

import darkUniverseExchangeJson from "../../Assets/Json/darkPay.json";

import leftvector from "../../Assets/contract/leftvector.png";
import rightvector from "../../Assets/contract/rightvector.png";

import deploy from "../../Assets/contract/deploy.png";
import interfaces from "../../Assets/contract/interface.png";
import creation from "../../Assets/contract/creation.png";
import point from "../../Assets/contract/point.png";
import automated from "../../Assets/contract/automated.png";
import instant from "../../Assets/contract/instant.png";
import darkuniverse from "../../Assets/Json/comet.json";
import axios from "axios";
import configurl from "../../config/config";
const dueData = [
  {
    id: 1,
    img: darkPayImageOne,
    title: "Vision for the Metaverse Payment Gateway",
    content:
      "Dark Universe envisions a Metaverse where financial interactions are as effortless as a single click. Our one-click decentralized payment gateway is designed to seamlessly integrate with Metaverse environments, making transactions swift, secure, and user-friendly.",
  },
  {
    id: 2,
    img: darkPayImageTwo,
    title: "Decentralized Architecture",
    content:
      "The payment gateway operates on a decentralized architecture, leveraging the principles of blockchain technology to ensure transparency, security, and trust. By decentralizing payment processing, Dark Universe eliminates the need for intermediaries, putting control back into the hands of users",
  },
  {
    id: 3,
    img: darkPayImageThree,
    title: "One-Click Simplicity ",
    content:
      "Dark Universe's payment gateway is distinguished by its simplicity. With just one click, users can initiate transactions within the Metaverse, streamlining the payment process and eliminating the need for cumbersome steps. This user-friendly approach encourages widespread adoption  seamless financial interactions.",
  },
];

const DarkPay = () => {
  const [contract, setContract] = useState([
    {
      id: 1,
      image: deploy,
      title: "Democratizing smart Contract Deployment",
      desc: "Dark Universe is committed to democratizing access to blockchain technology. The Zero-Code Smart Contract Deployment feature ensures that even those without extensive coding experience can actively participate in the creation and execution of smart contracts within the Dark Universe ecosystem.",
    },
    {
      id: 2,
      image: interfaces,
      title: "Intuitive User Interface",
      desc: "The zero-code experience is facilitated through an intuitive user interface that guides developers through the process of deploying smart contracts seamlessly.The UI is designed to be user-friendly, eliminating the technical barriers traditionally associated with coding,compiling, and deploying smart contracts.",
    },
    {
      id: 3,
      image: creation,
      title: "Template-Based Contract Creation",
      desc: "Dark Universe’s Zero-Code smart contract deployment leverages templates that encapsulate common functionalities and use cases. Developers can choose from a variety of templates that best suit their needs,customizing and deploying contracts effortlessly without delving into the complexities of coding ",
    },
    {
      id: 4,
      image: point,
      title: "Point-and-Click Functionality",
      desc: "The deployment process is as simple as point-and-click.Developers can select desired contract features, define parameters through an intuitive interface, and deploy contracts with a few clicks. This streamlined approach ensures that the smart contract creation process is accessible to a wider audience.",
    },
    {
      id: 5,
      image: automated,
      title: "Automated Code Generation",
      desc: "Behind the scenes, Dark Universe’s infrastructure automates the code generation process. The system interprets the developer’s choices and preferences,translating them onto the necessary code for the smart contract. This automation ensures accuracy and eliminates the need for manual coding.",
    },
    {
      id: 6,
      image: instant,
      title: "Instant Deployment and Activation",
      desc: "Zero-Code smart contract deployment enables instant deployment and activation. Once the developer finalizes the contract parameters, the system handles the rest, Swiftly deploying the smart contract onto the Dark Universe blockchain. This rapid deployment accelerates the pace of innovation within the ecosystem.",
    },
  ]);

  const [CmsDrakPay, setCmsDrakPay] = useState([]);
  console.log("CmsDrakPay_CmsDrakPay_CmsDrakPay", CmsDrakPay);

  const GetCmsDrakPay = async () => {
    axios.get(configurl.backurl + "/api/GetCmsDrakPay").then((res) => {
      // if(res.data.identifier = "NFT"){
      console.log("newdata_cometure");
      setCmsDrakPay(res.data);
    });
  };

  useEffect(() => {
    GetCmsDrakPay();
  }, []);

  return (
    <section className="hm_due pt-5 pb-3">
      <Container className="container_90">
        <div className="row pt-4 pb-4 position-relative">
          <Lottie
            className="darkjson1 jsontop"
            animationData={darkuniverse}
            loop={true}
          />
          <div className="col-11 col-sm-8 col-md-5 col-lg-4 col-xl-3 mx-auto">
            <div className="titleback">
              <h5>{`${
                CmsDrakPay[0]?.identifier == "Dark_Pay" ? (
                  CmsDrakPay[0]?.content
                ) : (
                  <></>
                )
              }`}</h5>
            </div>
          </div>
        </div>

        <div className="row pt-3 pb-3 pb-md-4">
          {/* <Lottie className="darkjson11" animationData={darkuniverse} loop={true} /> */}
          {/* <Lottie className="darkjson2" animationData={darkuniverse} loop={true} /> */}
          <div className="col-md-11 col-lg-9 mx-auto text-center">
            <h1 className="heading1 mb-3">{`${
              CmsDrakPay[1]?.identifier == "Dark_Pay" ? (
                CmsDrakPay[1]?.content
              ) : (
                <></>
              )
            }`}</h1>
            <p className="hm_subtle_org text-center mt-3 mt-xl-4">
              {`${
                CmsDrakPay[2]?.identifier == "Dark_Pay" ? (
                  CmsDrakPay[2]?.content
                ) : (
                  <></>
                )
              }`}
            </p>
            <p className="subheading">
              {" "}
              {`${
                CmsDrakPay[3]?.identifier == "Dark_Pay" ? (
                  CmsDrakPay[3]?.content
                ) : (
                  <></>
                )
              }`}
            </p>
          </div>
        </div>

        {/* <h5 className="hm_hds_wht text-center ">Dark Universe Dark pay</h5>
        <p className="hm_subtle_org text-center mt-3 mt-xl-4">
          Pioneering a One-Click Decentralized Payment Gateway for the Metaverse
        </p>
        <p className="hm_dls_wht text-center">
          In the dynamic landscape of decentralized finance (DeFi), Dark
          Universe Exchange emerges as a powerhouse, redefining the trading
          experience with a state-of-the-art platform that seamlessly integrates
          all the essential features. Our decentralized exchange is not just a
          marketplace; it's an ecosystem that encompasses Yield Farming, Crypto
          Lending, and more, wrapped in an unparalleled user experience and
          interface design.
        </p> */}

        <div className="row  justify-content-center">
          <div className="col-lg-9 my-3 my-xl-5  mx-auto">
            <Lottie
              className="img-fluid text-center hm_drkpay_lot"
              animationData={darkUniverseExchangeJson}
              loop={true}
            />
          </div>
        </div>

        <div className="row list pb-5 justify-content-center">
          {/* {dueData.map((data) => (
            <div className="col-md-6 col-lg-6 col-xl-4 mt-5">
              <div className="card">
                <img
                  src={darkPayImageOne}
                  alt="image"
                  className="img-fluid trackimg"
                />
                <p className="hm_blg_tle mt-3 mb-0">
                  {" "}
                  Vision for the Metaverse Payment Gateway
                </p>
                <p className="mt-2 subheading hm_drk_pay_cnt">
                  {" "}
                  Dark Universe envisions a Metaverse where financial interactions are as effortless as a single click. Our one-click decentralized payment gateway is designed to seamlessly integrate with Metaverse environments, making transactions swift, secure, and user-friendly.
                </p>
                <button className="hm_cpy_btn mt-3">
                  Learn More
                </button>
              </div>
            </div>
          ))} */}
          <div className="col-md-6 col-lg-6 col-xl-4 mt-5">
            <div className="card">
              <img
                src={darkPayImageOne}
                alt="image"
                className="img-fluid trackimg"
              />
              <p className="hm_blg_tle mt-3 mb-0">
                {" "}
                {`${
                  CmsDrakPay[4]?.identifier == "Dark_Pay" ? (
                    CmsDrakPay[4]?.content
                  ) : (
                    <></>
                  )
                }`}
              </p>
              <p className="mt-2 subheading hm_drk_pay_cnt">
                {" "}
                {`${
                  CmsDrakPay[5]?.identifier == "Dark_Pay" ? (
                    CmsDrakPay[5]?.content
                  ) : (
                    <></>
                  )
                }`}
              </p>
              {/* <button className="hm_cpy_btn mt-3">Learn More</button> */}
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-4 mt-5">
            <div className="card">
              <img
                src={darkPayImageOne}
                alt="image"
                className="img-fluid trackimg"
              />
              <p className="hm_blg_tle mt-3 mb-0">
                {" "}
                {`${
                  CmsDrakPay[6]?.identifier == "Dark_Pay" ? (
                    CmsDrakPay[6]?.content
                  ) : (
                    <></>
                  )
                }`}
              </p>
              <p className="mt-2 subheading hm_drk_pay_cnt">
                {" "}
                {`${
                  CmsDrakPay[7]?.identifier == "Dark_Pay" ? (
                    CmsDrakPay[7]?.content
                  ) : (
                    <></>
                  )
                }`}
              </p>
              {/* <button className="hm_cpy_btn mt-3">Learn More</button> */}
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-4 mt-5">
            <div className="card">
              <img
                src={darkPayImageOne}
                alt="image"
                className="img-fluid trackimg"
              />
              <p className="hm_blg_tle mt-3 mb-0">
                {" "}
                {`${
                  CmsDrakPay[8]?.identifier == "Dark_Pay" ? (
                    CmsDrakPay[8]?.content
                  ) : (
                    <></>
                  )
                }`}
              </p>
              <p className="mt-2 subheading hm_drk_pay_cnt">
                {" "}
                {`${
                  CmsDrakPay[9]?.identifier == "Dark_Pay" ? (
                    CmsDrakPay[9]?.content
                  ) : (
                    <></>
                  )
                }`}
              </p>
              {/* <button className="hm_cpy_btn mt-3">Learn More</button> */}
            </div>
          </div>
        </div>

        {/* <Row className="justify-content-center justify-content-xxl-start ">
          {dueData.map((data) => (
            <Col
              xs={12}
              lg={6}
              xl={4}
              className="d-flex list justify-content-center justify-content-xl-start
             my-sm-5 my-md-3 mb-md-0"
            >
              <div className="card">
                <div className="">
                  <img
                    src={data.img}
                    alt={data.title}
                    className="img-fluid"
                  />
                  <p className="hm_blg_tle">
                    {" "}
                    {data.title}
                  </p>
                  <p className="">
                    {" "}
                    {data.content}
                  </p>
                  <button className="hm_cpy_btn mt-1 mt-md-3 mb-md-3">
                    Learn More
                  </button>
                </div>
              </div>
            </Col>
          ))}
        </Row> */}

        {/* <div className="row pt-3 pt-sm-4 pb-5">
          {contract.map((e, i) =>
            <div className="col-md-6 col-lg-4 mt-5">
              <div className="contractborder darkpayborder">
                <img src={e.image} className="img-fluid featureimg contractimg" />
                <h5 className="mt-4">{e.title}</h5>
                <p className="subheading mb-0">{e.desc}</p>
              </div>
            </div>
          )}
        </div> */}

        {/* <div className="row pt-4 pb-3 position-relative">
          <img src={leftvector} className="leftvector" alt="image" />
          <img src={rightvector} className="rightvector" alt="image" />
          <div className="col-10 col-sm-5 col-md-3 col-lg-2 mx-auto">
            <button className="btn themebtn w-100">See all</button>
          </div>
        </div> */}
      </Container>
    </section>
  );
};

export default DarkPay;
