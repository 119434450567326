import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import { useEffect } from "react";
import { useState } from "react";
import due_card_img_one from "../../Assets/homepage/due_card_img_one.svg";
import due_card_img_two from "../../Assets/homepage/due_card_img_two.svg";
import due_card_img_three from "../../Assets/homepage/due_card_img_three.svg";

import darkUniverseExchangeJson from "../../Assets/Json/darkUnivserseExchange.json";
import leftvector from "../../Assets/contract/leftvector.png";
import rightvector from "../../Assets/contract/rightvector.png";
import darkuniverse from "../../Assets/Json/comet.json";
import axios from "axios";
import configurl from "../../config/config";
const dueData = [
  {
    id: 1,
    img: due_card_img_one,
    title: "Comprehensive Decentralized Exchange",
    content:
      "Dark Universe Exchange is not just a platform for trading; it's a comprehensive decentralized ecosystem. Users can seamlessly engage in spot trading, yield farming, crypto lending, and other financial activities, all within a unified and intuitive interface.",
  },
  {
    id: 2,
    img: due_card_img_two,
    title: "Yield Farming with Dark Coin",
    content:
      "Dark Universe Exchange introduces Yield Farming opportunities, allowing users to rewards by staking Dark Coins and participating in liquidity pools. This incentivizes users to actively contribute to the liquidity of the exchange while earning attractive yields.",
  },
  {
    id: 3,
    img: due_card_img_three,
    title: "Crypto Lending Platform",
    content:
      "Dark Universe Exchange doubles as a crypto lending platform, providing users with the ability to lend and borrow various cryptocurrencies. With competitive interest rates and a user-friendly interface, the lending platform offers a seamless experience for those looking to leverage their assets or earn passive income.",
  },
];

const DarkUniverseExchange = () => {
  const [cmsDrakDex, SetcmsDrakDex] = useState([]);

  console.log("cmsDrakDex_cmsDrakDexcmsDrakDex", cmsDrakDex);

  const getAlldata = async () => {
    axios.get(configurl.backurl + "/api/GetCmsDarkDex").then((res) => {
      SetcmsDrakDex(res.data);
    });
  };

  useEffect(() => {
    getAlldata();
  }, []);

  return (
    <section className="hm_due pt-5 pb-4">
      <Container className="container_90">
        <div className="row pt-4 pb-4 position-relative">
          <Lottie
            className="darkjson1 jsontop"
            animationData={darkuniverse}
            loop={true}
          />
          <div className="col-11 col-sm-8 col-md-5 col-lg-4 col-xl-3 mx-auto">
            <div className="titleback">
              <h5>{`${
                cmsDrakDex[0]?.identifier == "DarkDex" ? (
                  cmsDrakDex[0]?.content
                ) : (
                  <></>
                )
              }`}</h5>
            </div>
          </div>
        </div>

        <div className="row pt-3 pb-3 pb-md-4">
          <div className="col-md-11 col-lg-9 mx-auto text-center">
            <h1 className="heading1 mb-3">
              {`${
                cmsDrakDex[1]?.identifier == "DarkDex" ? (
                  cmsDrakDex[1]?.content
                ) : (
                  <></>
                )
              }`}
              e
            </h1>
            <p className="hm_subtle_org text-center mt-3 mt-xl-4">
              {`${
                cmsDrakDex[2]?.identifier == "DarkDex" ? (
                  cmsDrakDex[2]?.content
                ) : (
                  <></>
                )
              }`}
            </p>
            <p className="subheading">
              {" "}
              {`${
                cmsDrakDex[3]?.identifier == "DarkDex" ? (
                  cmsDrakDex[3]?.content
                ) : (
                  <></>
                )
              }`}
            </p>
          </div>
        </div>

        {/* <h5 className="hm_hds_wht text-center ">Dark Universe Exchange</h5>
        <p className="hm_subtle_org text-center mt-3 mt-xl-4">
          Unleashing the Future of Decentralized with Cutting-Edge Features and
          Intuitive UI/UX Design
        </p>
        <p className="hm_dls_wht text-center">
          In the dynamic landscape of decentralized finance (DeFi), Dark
          Universe Exchange emerges as a powerhouse, redefining the trading
          experience with a state-of-the-art platform that seamlessly integrates
          all the essential features. Our decentralized exchange is not just a
          marketplace; it's an ecosystem that encompasses Yield Farming, Crypto
          Lending, and more, wrapped in an unparalleled user experience and
          interface design.
        </p> */}

        <div className="row mt-4 mt-xl-5">
          <div className="col-sm-7 col-md-8 col-lg-6 col-xl-5 mx-auto">
            <Lottie
              className="img-fluid text-center hm_due_lot"
              animationData={darkUniverseExchangeJson}
              loop={true}
            />
          </div>
        </div>

        <Row className="justify-content-center justify-content-xxl-start mt-5">
          {/* {dueData.map((data) => (
            <Col
              xs={12}
              lg={6}
              xl={4}
              xxl={4}
              className="d-flex justify-content-center justify-content-xl-start
               mb-md-4"
            >
              <div className="hm_due_crd ">
                <div className="">
                  <img
                    src={data.img}
                    alt={data.title}
                    className="img-fluid hm_due_crd_img"
                  />
                  <p className="hm_blg_tle my-3"> {data.title}</p>
                  <p className="hm_due_crd_cnt"> {data.content}</p>
                </div>
              </div>
            </Col>
          ))} */}
          <Col
            xs={12}
            lg={6}
            xl={4}
            xxl={4}
            className="d-flex justify-content-center justify-content-xl-start
               mb-md-4"
          >
            <div className="hm_due_crd ">
              <div className="">
                <img
                  src={due_card_img_one}
                  alt="images"
                  className="img-fluid hm_due_crd_img"
                />
                <p className="hm_blg_tle my-3">
                  {" "}
                  {`${
                    cmsDrakDex[4]?.identifier == "DarkDex" ? (
                      cmsDrakDex[4]?.content
                    ) : (
                      <></>
                    )
                  }`}
                </p>
                <p className="hm_due_crd_cnt">{`${
                  cmsDrakDex[5]?.identifier == "DarkDex" ? (
                    cmsDrakDex[5]?.content
                  ) : (
                    <></>
                  )
                }`}</p>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            lg={6}
            xl={4}
            xxl={4}
            className="d-flex justify-content-center justify-content-xl-start
               mb-md-4"
          >
            <div className="hm_due_crd ">
              <div className="">
                <img
                  src={due_card_img_one}
                  alt="images"
                  className="img-fluid hm_due_crd_img"
                />
                <p className="hm_blg_tle my-3">
                  {" "}
                  {`${
                    cmsDrakDex[6]?.identifier == "DarkDex" ? (
                      cmsDrakDex[6]?.content
                    ) : (
                      <></>
                    )
                  }`}
                </p>
                <p className="hm_due_crd_cnt">{`${
                  cmsDrakDex[7]?.identifier == "DarkDex" ? (
                    cmsDrakDex[7]?.content
                  ) : (
                    <></>
                  )
                }`}</p>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            lg={6}
            xl={4}
            xxl={4}
            className="d-flex justify-content-center justify-content-xl-start
               mb-md-4"
          >
            <div className="hm_due_crd ">
              <div className="">
                <img
                  src={due_card_img_one}
                  alt="images"
                  className="img-fluid hm_due_crd_img"
                />
                <p className="hm_blg_tle my-3">
                  {" "}
                  {`${
                    cmsDrakDex[8]?.identifier == "DarkDex" ? (
                      cmsDrakDex[8]?.content
                    ) : (
                      <></>
                    )
                  }`}
                </p>
                <p className="hm_due_crd_cnt">{`${
                  cmsDrakDex[9]?.identifier == "DarkDex" ? (
                    cmsDrakDex[9]?.content
                  ) : (
                    <></>
                  )
                }`}</p>
              </div>
            </div>
          </Col>
        </Row>

        {/* <div className="row pt-4 pb-3 position-relative">
          <img src={leftvector} className="leftvector" alt="image" />
          <img src={rightvector} className="rightvector" alt="image" />
          <div className="col-10 col-sm-5 col-md-3 col-lg-2 mx-auto">
            <button className="btn themebtn w-100">See all</button>
          </div>
        </div> */}
      </Container>
    </section>
  );
};

export default DarkUniverseExchange;
