import React from "react";
import { Container } from "react-bootstrap";
import roadMapImg from "../../Assets/homepage/roadmap.svg";

const RoadMap = () => {
  return (
    <section className="hm_rdmp pt-5 pb-4">
      <Container className="container_90">
      <div className="row pt-4 pb-4">
                        <div className="col-11 col-sm-8 col-md-5 col-lg-4 col-xl-3 mx-auto">
                            <div className="titleback">
                                <h5>Roadmap</h5>
                            </div>
                        </div>
                    </div>

        <div className="hm_rdmp_img_wrp py-3 py-sm-4 py-lg-5 d-flex justify-content-center">
          <img src={roadMapImg} alt="" className="img-fluid" />
        </div>
      </Container>
    </section>
  );
};

export default RoadMap;
